@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
.memo .paper {
    position: relative;
    left: 15px;
    height: 450px;
    width: 320px;
    margin-bottom: 20px;
    background: antiquewhite;
    box-shadow: 0px 2px 5px 0px #888;
}

.memo .paper::before {
    content: '';
    position: absolute;
    left: 45px;
    height: 100%;
    width: 2px;
    /* background: rgba(255, 0, 0, 0.4); */
}

.memo .lines {
    margin-top: 40px;
    height: calc(100% - 40px);
    width: 100%;
    background-color: antiquewhite;
}

.memo .lines h3 {
    display: block;
    font-size: 15px;
    line-height: 25px;
    margin-left: 160px;
    font-weight: bold;
}

.memo .text {
    position: absolute;
    top: 26px;
    left: 55px;
    bottom: 10px;
    right: 10px;
    line-height: 30px;
    font-family: 'マメロン';
    color: #555;
    overflow: hidden;
    outline: none;
}

.memo .holes {
    position: absolute;
    left: 10px;
    height: 25px;
    width: 25px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: inset 0px 2px 3px 0px #888;
}

.memo .hole-top {
    top: 10%;
}

.memo .hole-middle {
    top: 50%;
}

.memo .hole-bottom {
    bottom: 10%;
}