    .recipeStep {
        background-color: rgba(255, 255, 255, 0);
    }
    
    .reciperoot {}
    
    .recipeStep .stepper {
        background-color: rgba(255, 255, 255, 0);
    }
    
    .recipeStep .stepper .p {}
    
    .recipeStep .resetContainer {
        position: relative;
        top: 30px;
        display: block;
        margin-top: 100px;
    }
    
    .recipeStep .resetContainer p {
        margin: 0px;
        padding: 0px;
    }