.searchResult {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    .container {
        // display: flex;
        // flex-flow: row wrap;
        // justify-content: space-evenly;
        // justify-items: flex-start;
        position: relative;
        >div {
            position: absolute;
            transition: all 0.2s ease;
            box-shadow: 2px 3px 5px rbga(0, 0, 0, 0.5);
        }
        * {
            position: relative;
        }
    }
    .MuiButton-root {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
    }
}