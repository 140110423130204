.menu-box {
    width: 100%;
    height: 100%;
    min-width: 5rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    .mise-div {
        flex: 1 0 13rem;
        margin: 0.5rem 0;
        display: block;
        width: 100%;
        // height: 13rem;
        background-size: cover;
        overflow-y: hidden;
        -ms-overflow-style: none;
        /* IE, Edge 対応 */
        scrollbar-width: none;
        /* Firefox 対応 */
        &::-webkit-scrollbar {
            display: none;
        }
        .wrap {
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            position: relative;
            padding: 12px;
            top: 70%;
            width: 100%;
            min-height: 60%;
            transition: top 0.2s ease;
            &:hover {
                top: 40%;
            }
        }
    }
}