div.recipeprintpages {
    display: block;
    width: 1200px;
    height: 1730px;
    background-color: rgb(255, 255, 255);
}

div.recipeprintpages img {
    position: relative;
    margin-top: 80px;
    left: -90px;
    width: 900px;
    display: inline-block;
}

div.recipeprintpages .MuiStepIcon-root {
    color: orange;
}

div.recipeprintpages .MuiStepLabel-label {
    color: #666;
}

div.recipeprintpages .MuiStepConnector-lineVertical {
    visibility: hidden;
}

div.recipeprintpages h2 {
    position: relative;
    top: -430px;
    margin: 0px 10px 25px -10px;
    font-size: 38px;
    /* background-color: #ddd; */
    color: #666;
    padding: 0px;
    font-family: 'Noto Sans JP';
    font-weight: 900;
}

div.recipeprintpages div.icon img {
    width: 120px;
    margin: 10px 20px;
}

div.recipeprintpages div.box {
    vertical-align: top;
    margin: 0px 30px;
}

div.recipeprintpages h3 {
    /* color: orange; */
}

#mem {
    position: relative;
    top: -300px;
    left: 700px;
}

#Mate {
    position: relative;
    width: 1200px;
    top: -255px;
    left: 100px;
    /* background-color: #555; */
}

#ReciSte {
    position: relative;
    top: -510px;
    left: 550px;
    /* background-color: antiquewhite; */
}

#Mate h3 {
    margin-bottom: 30px;
}

#ReciSte h3 {
    margin-left: 10px;
    margin-bottom: 10px;
}

@media print {
    .sidebar {
        display: none !important;
    }
    .recipe {
        display: none !important;
    }
    .topbar {
        display: none !important;
    }
}