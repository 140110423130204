.sidebar {
    display: block;
    // background-color: bisque;
    width: 30%;
    img {
        display: block;
        height: 100%;
        width: 100%;
    }
    .sear {
        display: inline-block;
        background-color: #4C7788;
        width: 100%;
        height: auto;
        margin: 10px;
        margin: 0;
        margin-bottom: 3rem;
    }
    .heal {
        display: inline-block;
        background-color: #fc709a;
        width: calc(50% - 1rem);
        height: auto;
        margin: 0;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
    .cookbook {
        display: inline-block;
        background-color: #fdcd5c;
        width: calc(50% - 1rem);
        height: auto;
        margin: 0;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
    .mypage {
        display: inline-block;
        background-color: #6febfc;
        width: calc(50% - 1rem);
        height: auto;
        margin: 0;
        margin-right: 1rem;
        margin-top: 1rem;
    }
    .rogo {
        display: inline-block;
        background-color: rgb(189, 196, 193);
        width: calc(50% - 1rem);
        height: auto;
        margin: 0;
        margin-left: 1rem;
        margin-top: 1rem;
    }
}


/* 細いバージョン */

.sidebar-min .sidebar {
    background-color: #4C7788;
    width: 10rem;
    .sear {
        display: inline-block;
        background-color: #4C7788;
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 3rem;
        margin-top: 1rem;
    }
    .heal {
        display: inline-block;
        background-color: #4C7788;
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 3rem;
    }
    .cookbook {
        display: inline-block;
        background-color: #4C7788;
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 3rem;
    }
    .mypage {
        display: inline-block;
        background-color: #4C7788;
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 3rem;
    }
    .rogo {
        display: inline-block;
        background-color: #4C7788;
        width: 100%;
        height: auto;
        margin: 0;
    }
}