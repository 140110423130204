.material {
    width: calc(25% - 5px);
    height: 100%;
    /* background-color: aqua; */
}

.material .border {
    display: inline-block;
    width: 3px;
    height: 20px;
    background-color: #4C7788;
    vertical-align: middle;
    margin-right: 5px;
}