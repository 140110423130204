.review {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0);
}

.review .box {
    height: 300px;
    text-align: center;
}

.review .box img.add {
    display: block;
    width: 250px;
    height: 250px;
    margin: 15px;
    background-color: #c8f9ff;
}

.review .box img {
    display: block;
    width: 180px;
    height: 180px;
    margin: auto;
}

.review .box p {
    padding: 0px 10px;
    margin-top: 10px;
}