div.mainPage {
    display: flex;
    height: 100%;
    /* max-width: 1300px; */
    margin-left: auto;
    margin-right: auto;
    /* padding: 0 10px; */
    flex-flow: row nowrap;
    main {
        // flex: 1 0 800px;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}
// .sidebar-min main {
//     margin-left: 25rem;
// }
