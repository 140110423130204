.recipe {
    display: block;
    width: 100%;
    height: 100vh;
}

.recipe .wrap1 {
    width: 410px;
    height: 100%;
    vertical-align: top;
}

.recipe .wrap1 .box1 {
    display: block;
    margin: auto;
    width: 372px;
    height: 124px;
    margin-top: 20px;
    margin-left: 23px;
}

.recipe .wrap1 .box1 img {
    width: 106px;
    padding: 10px;
    background-color: #c8f9ff;
}

.recipe .wrap1 .box1 .back {
    background-color: #8a8a8a;
}

.recipe .wrap1 .box2 {
    display: block;
    width: 350px;
    height: calc(100vh - 258px);
    background-color: white;
    margin: 10px auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    /* Firefox 対応 */
}

.recipe .wrap1 .box2::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.recipe .wrap1 .box3 {
    display: block;
    margin: auto;
    width: 376px;
    height: 94px;
    margin-left: 23px;
}

.recipe .wrap1 .box3 img {
    background-color: #c8f9ff;
    width: 75px;
    padding: 10px;
}

.recipe .wrap2 {
    width: calc(100% - 410px);
    height: 100%;
    vertical-align: top;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    /* Firefox 対応 */
}

.recipe .wrap2::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.recipe .wrap2 h3 {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 30px;
}

.recipe .wrap2 .box1 {
    margin-top: 30px;
    display: block;
    width: calc(100% - 70px);
    height: calc(35% - 50px);
    background-color: #ffe9ac;
    padding: 20px;
}

.recipe .wrap2 .box2 {
    display: block;
    width: calc(100% - 70px);
    height: calc(70% - 90px);
    background-color: #ffe6ee;
    margin-top: 30px;
    padding: 20px;
}

.recipe .wrap2 .box3 {
    display: block;
    width: calc(100% - 70px);
    height: auto;
    background-color: white;
    margin-top: 30px;
    padding: 20px;
}

.recipe .wrap2 .box1 .cont1 {}