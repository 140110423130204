#LoginForm {}

#LoginForm form {
    width: 30rem;
    height: auto;
    background-color: white;
    padding: 50px;
    margin: auto;
}

#LoginForm form .box1 {
    display: block;
    padding-bottom: 20px;
}

#LoginForm form .box2 {
    display: block;
    padding-bottom: 20px;
}

#LoginForm form .box3 {
    width: 20rem;
    margin: auto;
    margin-top: 20px;
}

#LoginForm form .box3 .contant1 {
    display: inline-block;
    padding-right: 20px;
}

#LoginForm form .box3 .contant2 {
    display: inline-block;
}