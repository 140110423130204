.health {
    /* width: calc(100vw - 170px); */
    height: 100vh;
    min-width: 1300px;
    .wrap1 {
        width: calc((100% / 100) * 30 - 60px);
        height: calc(100% - 60px);
        background-color: #ffffff;
        vertical-align: top;
        margin: 30px;
        .box1 {
            display: block;
            width: 100%;
            text-align: center;
            h3 {
                margin-top: 10px;
            }
        }
        .box2 {
            display: block;
            width: 100%;
            height: 12rem;
            h3 {
                font-size: 15px;
                text-align: center;
                font-weight: bold;
                margin-bottom: 10px;
                margin-top: 10px;
            }

            .cont1 {
                width: 20%;
                vertical-align: middle;
            }
            .cont2 {
                width: 60%;
                vertical-align: middle;
                overflow: hidden;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                img {
                    display: block;
                    height: 9rem;
                    width: 9rem;
                    object-fit: cover;
                    margin: auto;
                }
            }
            .cont3 {
                width: 20%;
                vertical-align: middle;
            }
            .cont4 {
            }
        }
        .box3 {
            padding: 0px 20px;
        }
        .box4 {
            margin: 10px 0px 0px 0px;
            // background-color: #e7eeff;
        }
    }
    .wrap2 {
        width: calc((100% / 100) * 70);
        height: 100%;
        /* background-color: antiquewhite; */
        vertical-align: top;
        .graf1 {
            background-color: #ffffff;
            vertical-align: top;
            margin-top: 30px;
            text {
                /* font-weight: bold; */
                font-size: 19px;
            }
        }
        .graf2 {
            background-color: #8bd3dd;
            vertical-align: top;
            margin-top: 30px;
            margin-left: 30px;
            margin-right: 30px;
        }
        .graf3 {
            width: auto;
            background-color: #ffdc8c;
            vertical-align: top;
            width: calc(100% - 640px);
            margin-top: 30px;
        }
        .box1 {
        }
        .box2 {
            display: block;
            width: calc((100% - 30px));
            height: calc(100% - 340px);
            margin: 30px 0px;
            // background-color: #e7eeff;
            .cont1 {
                width: calc(((100%) / 7) * 4);
                height: 100%;
                background-color: #ffffff;
                vertical-align: top;
                text-align: center;
                h3 {
                    margin-top: 15px;
                }
                img {
                    height: calc(100% - 220px);
                }
                p {
                    font-size: 20px;
                    margin: 5px auto 20px auto;
                }
                .div1 {
                    width: 20%;
                    height: 80px;
                    vertical-align: top;
                    img {
                        height: 150px;
                    }
                }
                .div2 {
                    width: 70%;
                    border: 1px solid #5f5f5f;
                    border-radius: 5px;
                    vertical-align: top;
                    margin-top: 40px;
                    padding: 5px 10px;
                    line-height: 1.5rem;
                    p {
                        display: inline;
                        width: 100px;
                        word-break: break-word;
                        line-height: 10px;
                    }
                }
            }
            .cont2 {
                width: calc(((100%) / 7) * 3);
                height: 100%;
                // background-color: #125058;
                vertical-align: top;
                overflow: auto;
            }
        }
    }
}

.health .wrap1 .box5,
.health .wrap1 .box6,
.health .wrap1 .box7 {
    margin: 0.1rem 1rem 0 1rem;
}

.health .wrap1 .box5 .MuiTableCell-sizeSmall,
.health .wrap1 .box6 .MuiTableCell-sizeSmall,
.health .wrap1 .box7 .MuiTableCell-sizeSmall {
}

.health .wrap1 .box5 h3,
.health .wrap1 .box6 h3,
.health .wrap1 .box7 h3 {
    display: inline-block;
    width: 20%;
    text-align: right;
    // font-size: 25px;
    // margin-top: 20px;
    font-size: 1.6rem;
    margin: 0.4rem 0 0 0;
}

.health .wrap1 .box5 p,
.health .wrap1 .box6 p,
.health .wrap1 .box7 p {
    display: inline-block;
    width: 80%;
    text-align: right;
    font-size: 1.2rem;
    margin-bottom: 0px;
    margin: 0.1rem;
}
