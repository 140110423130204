.newRecipe {
    display: block;
    width: 100%;
    height: auto;
    background-color: #fafafa;
}

.dropzoneClass {
    max-width: 500px;
}

.scrollIt {
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    /* Firefox 対応 */
    height: 100vh;
}

.scrollIt::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.newRecipe .plus {
    margin: 0 auto;
}
