div.Intro {
    height: 100%;
    /* background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);; */
}

#LoginForm {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.781);
    z-index: 10000;
    padding: 50px;
    text-align: center;
    transition: all 1s;
}

div.nav {
    height: 100px;
    width: 100%;
    border-bottom: 3px solid #fefefe;
    /* background-color: #d8d0bd; */
}

div.LogIn {
    display: inline-block;
    width: 20rem;
    vertical-align: top;
    padding-top: 30px;
    margin-left: 20px;
    /* height: 100vh;
    overflow: auto; */
    /* scroll-snap-type: y mandatory; */
}

div.LogIn span {
    font-size: 1.6rem;
    color: white;
    font-family: 'Noto Sans jp';
    transition: all 0.2s ease;
}

.LogIn a {
    text-decoration: none;
    color: white;
}
.LogIn span:hover {
    top: -25px;
}

div.sections {
    box-sizing: border-box;
    height: 100vh;
    scroll-snap-align: start;
}

div#sec1 {
    box-sizing: border-box;
    background-color: #ffdc8c;
}

div#sec1 p.catchCopy {
    max-width: 700px;
    padding-top: 10px;
    position: relative;
    text-align: left;
    top: 15%;
    left: 10%;
    font-size: 2.6rem;
    font-family: 'マメロン', 'Noto Sans jp';
    color: #333;
}

img#toplogo {
    display: inline-block;
    width: 80px;
    vertical-align: top;
    margin-top: 35px;
    margin-left: 10px;
}

img#chefimg {
    position: relative;
    height: 30%;
    top: 30%;
    left: 5%;
}

div.gif {
    height: 60%;
    width: 45%;
    margin-left: 50%;
    margin-top: -13%;
    background-image: url(../../images/GATTAI.gif);
    background-size: cover;
    background-position: center;
}

div#sec2 {
    position: relative;
    background-color: #ffeab8;
}

.slideR {
    display: flex;
    width: 100%;
}

.sideR {
    position: absolute;
    right: 0;
    width: 40%;
    height: 100vh;
    color: #333;
    background-color: #fefefe;
    transition-duration: 0.3s;
    z-index: 10;
}

.sideR:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    background: #8bd3dd;
    z-index: -1;
    transform-origin: left top;
    transform: scale(0, 1);
    transition: transform 0.4s;
}

#sec2:hover .sideR:before {
    transform-origin: right top;
    transform: scale(1, 1);
}

#sec2:hover .sideR {
    color: #fefefe;
}

div#sec2 h1,
div#sec3 h1 {
    text-align: center;
    padding-top: 10%;
    padding-right: 0%;
    font-size: 4rem;
    font-family: 'Noto Sans jp';
}

.desc {
    text-align: center;
    padding-top: 10%;
    font-size: 2rem;
    font-family: 'Noto Sans jp';
}

div#sec2 img#sam1 {
    position: absolute;
    height: 45%;
    top: 5%;
    left: 5%;
    box-shadow: #333;
}

div#sec2 img#sam2 {
    position: absolute;
    height: 60%;
    top: 35%;
    left: 15%;
    z-index: 2;
}

div#slideShow {
    height: 75vh;
    background-color: #ffdc8c;
}

div#sec3 {
    position: relative;
    background-color: #ffeab8;
}

.sideL {
    position: absolute;
    left: 0;
    width: 40%;
    height: 100vh;
    transition-duration: 0.3s;
    z-index: 10;
    color: #333;
    background-color: #fefefe;
}

.slideL {
    display: flex;
    width: 100%;
}

.sideL:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    background: #fe93bc;
    z-index: -1;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform 0.4s;
}

#sec3:hover .sideL:before {
    transform-origin: left top;
    transform: scale(1, 1);
}

#sec3:hover .sideL {
    color: #fefefe;
}

#fb {
    position: absolute;
    top: 10%;
    right: 20%;
    height: 40%;
}

/*上からフェードイン*/

.fade-in-top {
    opacity: 0;
    animation: fadein-top 1.5s 0.3s ease-out forwards;
}

@keyframes fadein-top {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.loop_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.loop_wrap img {
    width: auto;
    margin-top: 67.5px;
    height: 70%;
}

@keyframes loop {
    0% {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes loop2 {
    0% {
        transform: translateX(0);
    }
    to {
        transform: translateX(-200%);
    }
}

.loop_wrap img:first-child {
    animation: loop 50s -25s linear infinite;
}

.loop_wrap img:last-child {
    animation: loop2 50s linear infinite;
}
