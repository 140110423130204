.search {
    /* width: 100vw; */
    flex: 1;
    background-color: rgb(255, 255, 255);
    display: block;
    text-align: center;
    min-height: 80%;
    span {
        pointer-events: none;
    }
}

.search h3 {
    padding-top: 50px;
}

.tagsearch {
    text-align: left;
}

.keywordsearch {
    display: block;
    background-color: #e0e0e0;
    border-radius: 0px 5px 5px 0px;
}

.yasai {
    text-align: left;
}

h6 {
    font-size: 22px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
}

.selecttag {
    text-align: left;
}

.search hr {
}
