.calc button[data-setter] {
    outline: none;
    background: transparent;
    border: none;
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 50px;
    width: 42px;
    height: 50px;
    color: #F7958E;
    cursor: pointer;
}

.calc .left {
    margin-right: 5px;
}

.calc .left2 {
    position: relative;
    padding-left: 0px;
}

.calc button[data-setter]:hover {
    opacity: 0.5;
}

.calc .container {
    position: relative;
    top: 20px;
    width: 300px;
    margin: 0 auto;
}

.calc .setters {
    position: absolute;
    left: 56px;
    top: 70px;
}

.calc .minutes-set {
    float: left;
    margin-right: 28px;
}

.calc .seconds-set {
    float: right;
}

.calc .controlls {
    position: absolute;
    left: 60px;
    top: 130px;
    text-align: center;
}

.calc .display-remain-time {
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 65px;
    color: #F7958E;
}

.calc #pause {
    outline: none;
    background: transparent;
    border: none;
    margin-top: 10px;
    width: 50px;
    height: 50px;
    position: relative;
}

.calc .play::before {
    display: block;
    content: "";
    position: absolute;
    top: 8px;
    left: 16px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 22px solid #F7958E;
}

.calc .pause::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 12px;
    width: 15px;
    height: 30px;
    background-color: transparent;
    border-radius: 1px;
    border: 5px solid #F7958E;
    border-top: none;
    border-bottom: none;
}

.calc #pause:hover {
    opacity: 0.8;
}

.calc .e-c-base {
    fill: none;
    stroke: #B6B6B6;
    stroke-width: 4px
}

.calc .e-c-progress {
    fill: none;
    stroke: #F7958E;
    stroke-width: 4px;
    transition: stroke-dashoffset 0.7s;
}

.calc .e-c-pointer {
    fill: #FFF;
    stroke: #F7958E;
    stroke-width: 2px;
}

.calc #e-pointer {
    transition: transform 0.7s;
}

.calc h1 {
    margin-top: 150px;
    text-align: center;
}

.calc body {
    background-color: #f7f7f7;
}