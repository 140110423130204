:root {
    --darkBlue: #4c7788;
    --yellow: #ffdc8c;
    --pink: #fe93bc;
    --blue: #8bd3dd;
}

.darkBlue {
    background-color: var(--darkBlue) !important;
}

.yellow {
    background-color: var(--yellow) !important;
}

.pink {
    background-color: var(--pink) !important;
}

.blue {
    background-color: var(--blue) !important;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // //https://segmentfault.com/a/1190000012800450
    scrollbar-width: thin;
    scrollbar-color: lightgrey transparent;
    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
        background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: darkgrey;
    }
    &::-webkit-scrollbar-track {
        background: white;
    }
}

html,
body,
#root {
    height: 100%;
}

.topBar {
    position: absolute;
    top: 0;
    right: 30px;
    border: var(--pink) solid 1px;
    color: blue;
    pointer-events: none;
    p {
        color: red;
    }
    button {
        pointer-events: all;
    }
}

.ikLazyCss {
    .wrap {
        min-width: 600px;
        width: 100%;
        margin: auto;
        letter-spacing: 0.1rem;
    }
    h2 {
        display: block;
        text-align: center;
        background-color: #4c7788;
        color: white;
        padding: 30px;
        font-size: 50px;
        letter-spacing: 0.5rem;
        font-weight: lighter;
        margin: 0 auto 30px;
    }
    h3 {
        font-size: 25px;
        font-weight: lighter;
        margin: 30px 0 0 0;
        margin-left: 10px;
    }
    h4 {
        font-weight: lighter;
        font-size: 15px;
        color: #ff8a8a;
        padding: 0;
        margin: 0;
    }
    Button {
        margin-top: 50px;
    }
    .next {
        background-color: #8bd3dd;
    }
    .stepBar {
        display: flex;
        max-width: 600px;
        position: relative;
        margin: 20px auto;
        text-align: center;
        padding: 0;
    }
    .stepBar li {
        font-size: 20px;
        list-style: none;
        position: relative;
        width: 33.333%;
    }
    .stepBar li:after {
        background: #d0e1f9;
        content: '';
        width: calc(100% - 48px);
        height: 4px;
        position: absolute;
        left: calc(-50% + 24px);
        top: 24px;
    }
    .stepBar li:first-child:after {
        display: none;
    }
    .stepBar li span {
        background: #d0e1f9;
        color: #ffffff;
        display: inline-block;
        height: 48px;
        margin-bottom: 5px;
        line-height: 48px;
        width: 48px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
    }
    .stepBar .visited:after {
        background: #7a96c7;
    }
    .stepBar .visited span {
        background: #849dc7;
    }
    .stepBar .visited {
        font-weight: bold;
    }
}

@media all and (-moz-min-device-pixel-ratio: 1.09) and (-moz-max-device-pixel-ratio: 1.18),
    (-webkit-min-device-pixel-ratio: 1.09) and (-webkit-max-device-pixel-ratio: 1.18),
    (min-resolution: 1.09dppx) and (max-resolution: 1.18dppx) {
    :root {
        font-size: 14px;
    }
}

@media all and (-moz-min-device-pixel-ratio: 1.19) and (-moz-max-device-pixel-ratio: 1.28),
    (-webkit-min-device-pixel-ratio: 1.19) and (-webkit-max-device-pixel-ratio: 1.28),
    (min-resolution: 1.19dppx) and (max-resolution: 1.28dppx) {
    :root {
        font-size: 13px;
    }
}

@media all and (-moz-min-device-pixel-ratio: 1.29) and (-moz-max-device-pixel-ratio: 1.4),
    (-webkit-min-device-pixel-ratio: 1.29) and (-webkit-max-device-pixel-ratio: 1.4),
    (min-resolution: 1.29dppx) and (max-resolution: 1.4dppx) {
    :root {
        font-size: 12px;
    }
}

@media all and (-moz-min-device-pixel-ratio: 1.41) and (-moz-max-device-pixel-ratio: 1.6),
    (-webkit-min-device-pixel-ratio: 1.41) and (-webkit-max-device-pixel-ratio: 1.6),
    (min-resolution: 1.41dppx) and (max-resolution: 1.6dppx) {
    :root {
        font-size: 10px;
    }
}

@media all and (-moz-min-device-pixel-ratio: 1.61) and (-moz-max-device-pixel-ratio: 1.8),
    (-webkit-min-device-pixel-ratio: 1.61) and (-webkit-max-device-pixel-ratio: 1.8),
    (min-resolution: 1.61dppx) and (max-resolution: 1.8dppx) {
    :root {
        font-size: 9px;
    }
}

@media all and (-moz-min-device-pixel-ratio: 1.81) and (-moz-max-device-pixel-ratio: 2.1),
    (-webkit-min-device-pixel-ratio: 1.81) and (-webkit-max-device-pixel-ratio: 2.1),
    (min-resolution: 1.81dppx) and (max-resolution: 2.1dppx) {
    :root {
        font-size: 8px;
    }
}
