.firstPage {
    display: block;
    width: 100%;
    height: 100%;
    .todayMenu {
        width: 68%;
        height: 100%;
        vertical-align: top;
        padding: 0px 30px 0px 30px;
        text-align: center;
        min-width: 450px;
        p {
            display: block;
            width: 80%;
            margin: auto;
            word-break: break-word;
            font-size: 25px;
            line-height: 2rem;
        }
        img {
            display: block;
            height: 30rem;
            width: 30rem;
            object-fit: cover;
            margin: auto;
        }
        h2 {
            font-size: 40px;
            padding: 0px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        h3 {
            font-size: 70px;
            padding-top: 30px;
        }
        h4 {
            color: rgb(55, 55, 55);
            padding-top: 20px;
            margin-bottom: 30px;
            font-size: 30px;
        }
    }
    .mise-container {
        width: 32%;
        display: inline-block;
        // float: right;
        height: 100vh;
        overflow: auto;
        padding: 0 1rem;
    }
}