.movie {}

.movie .wrapper {}

.movie .wrapper .boxi1 h3 {
    text-align: center;
}

.movie .wrapper .boxi2 .material {
    display: block;
    width: 300px;
    margin: 20px auto 0px;
}

.movie .wrapper .boxi3 {
    display: block;
    width: 300px;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 20px auto;
}

.movie .wrapper .boxi4 {
    display: block;
    margin: auto;
}