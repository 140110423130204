.cookbook {}

.cookbook .wrap1 {
    vertical-align: top;
    text-align: center;
    padding-top: 40px;
}

.cookbook .wrap1 img {
    display: block;
    width: 400px;
    margin: auto;
}

.cookbook .wrap1 .box1 {
    display: block;
    margin: auto;
    padding-left: calc((5%-400px)/2);
    margin-bottom: 50px;
}

.cookbook .wrap1 .box1 button {
    background-color: #ff8b91;
}

.cookbook .wrap1 .box2 {
    display: block;
    margin: auto;
    padding-left: calc((5%-400px)/2);
}

.cookbook .wrap1 .box2 button {
    background-color: #ffcf5f;
}

.cookbook .wrap2 {
    vertical-align: top;
    overflow-y: scroll;
    height: 100vh;
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    /* Firefox 対応 */
}

.cookbook .wrap2::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.cookbook .wrap2 .box1 {
    padding-top: 50px;
    display: grid;
    row-gap: 30px;
    grid-template-columns: repeat(3, minmax(350px, 1fr));
}

.cookbook .wrap2 .box1 .recipe_card {
    width: 350px;
    margin: auto;
}