.recipe_card {
    background-color: white;
    h2 {
        padding: 8px 0px;
        font-size: 18px;
        background-color: #252525;
        color: white;
        text-align: center;
        letter-spacing: 0.1rem;
        margin: 0;
    }
    p {
        margin: 0;
    }
    .cardinfo {
        font-size: 14px;
        letter-spacing: 0.02rem;
        line-height: 1.5rem;
    }
    .dish {
        display: block;
        text-align: center;
        width: 350px;
        margin: 0 auto;
        max-height: 700px;
    }
    .cardicon {
        display: inline-block;
        width: 75px;
        height: 90px;
    }
    table {
        font-size: 10px;
    }
    .MuiTableCell-sizeSmall {
        padding: 5px 10px 5px 10px;
    }
    .cardicon img {
        display: block;
        width: 55px;
        margin: 0 auto;
        margin-top: 10px;
    }
    .bukuma {
        display: block;
        margin: 4px auto 2px auto;
        padding-bottom: 3px;
        text-align: center;
        border-bottom: 1px solid #444444;
    }
    h5 {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin: 0;
    }
    .cardicon {
        text-align: center;
        padding: 5px 0px;
        p {
            line-height: 0.8rem;
        }
    }
    .cardicon p {
        font-weight: bold;
        color: #444444;
        letter-spacing: 0.1rem;
        padding-top: 2px;
        font-size: 11px;
    }
    .cardiconmaster {
        vertical-align: bottom;
    }
    .cardseibun {
        vertical-align: bottom;
    }
    hr {
        /* background-color: #F5F5F5; */
        color: #6d6d6d;
        margin: 0;
    }
    .cardtag {
        font-size: 10px;
    }
    .easy {
        background-color: #ffdcdc;
    }
    .normal {
        background-color: #ffeefe;
    }
    .difficult {
        background-color: #ffe7a3;
    }
    .master {
        background-color: #e1ffe2;
    }
    .min15 {
        background-color: #ff8b8b;
    }
    .money {
        background-color: #fffae0;
    }
    .pro {
        background-color: #f7f7f7;
    }
}